import { Fragment } from "react";
import cx from "classnames";
import styles from "./SrcSet.module.css";

export const SrcSet = ({
  alt,
  cropCenter,
  sizes = [
    { minWidth: 1400, width: 1920 },
    { minWidth: 1024, width: 1400 },
    { minWidth: 900, width: 1024 },
    { minWidth: 768, width: 900 },
    { minWidth: 700, width: 768 },
    { minWidth: 600, width: 700 },
    { minWidth: 500, width: 600 },
    { minWidth: 400, width: 500 },
    { minWidth: 345, width: 400 },
    { minWidth: 1, width: 345 },
  ],
  src,
  className,
  ...props
}) => (
  <picture className={styles.container} {...props}>
    {sizes.map(({ minWidth, width, ratio, height }, index) => {
      const url2x = createImgixUrl({
        cropCenter,
        src,
        ratio,
        width,
        height,
        dpr: 2,
      });
      const url1x = createImgixUrl({
        cropCenter,
        src,
        ratio,
        width,
        height,
        dpr: 1,
      });

      return (
        <Fragment key={`${src}-${width}-${index}`}>
          <source
            srcSet={`${url2x} ${width}w`}
            sizes={`(min-width: ${minWidth}px) ${width}px`}
            media={`(min-width: ${minWidth}px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: ${minWidth}px) and (min-resolution: 120dpi)`}
          />
          <source
            srcSet={`${url1x} ${width}w`}
            sizes={`(min-width: ${minWidth}px) ${width}px`}
            media={`(min-width: ${minWidth}px)`}
          />
        </Fragment>
      );
    })}
    <img className={cx(styles.image, className)} alt={alt} />
  </picture>
);

const createImgixUrl = ({
  cropCenter = false,
  src,
  width,
  ratio,
  height = "",
  dpr = 1,
}) => {
  if (!src) return "";
  const srcWithoutArguments = src.split("?")[0];
  const q = dpr === 2 ? 20 : 40;

  if (height) {
    height = `&h=${height}`;
  } else if (ratio) {
    height = `&h=${Math.round(width / ratio)}`;
  }

  const crop = cropCenter ? "center" : "faces,edges";

  return `${srcWithoutArguments}?auto=compress,format&fit=crop&crop=${crop}&q=${q}&dpr=${dpr}&w=${width}${height}`;
};
