import { IconStar } from "components/Icons";
import styles from "./StarRating.module.css";

const STAR_SIZE = 20;
const STAR_SPACE = 4;
const STAR_UNIT = STAR_SIZE + STAR_SPACE;

export const StarRating = ({ rating = 0, total = 5 }) => {
  rating = Number.isNaN(rating) ? 0 : rating;

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.stars}
        style={{
          height: STAR_SIZE,
          width: total * STAR_UNIT,
        }}
      >
        <div className={styles.inActiveStars}>
          {[...new Array(total)].map((_, index) => (
            <IconStar
              key={index}
              total={total}
              className={styles.icon}
              style={{
                height: STAR_SIZE,
                marginRight: STAR_SPACE,
                width: STAR_SIZE,
              }}
            />
          ))}
        </div>

        <div
          className={styles.activeStars}
          style={{
            clip: `rect(0px,${
              rating * STAR_SIZE + rating * STAR_SPACE - STAR_SPACE / 2
            }px,${STAR_SIZE}px,0px)`,
          }}
        >
          {[...new Array(total)].map((_, index) => (
            <IconStar
              key={index}
              total={total}
              className={styles.icon}
              style={{
                height: STAR_SIZE,
                marginRight: STAR_SPACE,
                width: STAR_SIZE,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
