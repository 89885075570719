import { Link } from "components/Link";
import { PrismicRichText } from "@prismicio/react";
import { SMALL, WHITE } from "shared/variables";
import cx from "classnames";
import styles from "./RichText.module.css";

export const RichTextWrapper = ({ primary, size }) => (
  <RichText content={primary.rich_text} size={size} />
);

export const RichText = ({
  children = undefined,
  color = undefined,
  content = undefined,
  size = undefined,
}) => (
  <div
    className={cx(styles.richText, {
      [styles.small]: size === SMALL,
      [styles.white]: color === WHITE,
    })}
  >
    {content ? <PrismicRichText field={content} /> : children}
  </div>
);
