import { dateTimeToStringWithMonth } from "shared/dateUtils";
import { Heading3 } from "components/Heading3";
import { Link } from "components/Link";
import { SrcSet } from "components/SrcSet";
import styles from "./BlogGrid.module.css";

export const BlogGrid = ({ items, title, withBorderTop }) => (
  <div className={withBorderTop ? styles.borderTopContainer : ""}>
    {title && <Heading3 as="h2">{title}</Heading3>}
    <div className={styles.container}>
      {items
        .sort((a, b) =>
          a.first_publication_date < b.first_publication_date ? 1 : -1
        )
        .map((item) => (
          <Item
            key={item.uid}
            date={item.first_publication_date}
            link={item}
            {...item.data}
          />
        ))}
    </div>
  </div>
);

const Item = ({
  date,
  hero_title,
  overview_image_crop_center,
  overview_image,
  link,
}) => (
  <Link className={styles.item} document={link}>
    {overview_image?.url && (
      <SrcSet
        alt={overview_image.alt}
        cropCenter={overview_image_crop_center}
        src={overview_image.url}
        sizes={[
          { minWidth: 1024, width: 360, ratio: 1.5 },
          { minWidth: 900, width: 465, ratio: 1.5 },
          { minWidth: 768, width: 400, ratio: 1.5 },
          { minWidth: 700, width: 768, ratio: 1.5 },
          { minWidth: 600, width: 660, ratio: 1.5 },
          { minWidth: 500, width: 560, ratio: 1.5 },
          { minWidth: 400, width: 460, ratio: 1.5 },
          { minWidth: 345, width: 360, ratio: 1.5 },
          { minWidth: 1, width: 305, ratio: 1.5 },
        ]}
      />
    )}
    <div className={styles.date}>
      {dateTimeToStringWithMonth(date, true, true)}
    </div>
    <h3 className={styles.title}>{hero_title}</h3>
  </Link>
);
