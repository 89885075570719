import { Button } from "components/Button";
import { GREEN, SMALL, WHITE } from "shared/variables";
import { IconDownArrow } from "components/Icons";
import { Link } from "components/Link";
import { MaxWidth } from "components/MaxWidth";
import { RichText } from "components/slices/RichText";
import { useAppContext } from "shared/useAppContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./NewsletterForm.module.css";

export const NewsletterFormBlock = () => (
  <div className={styles.container}>
    <MaxWidth size={SMALL}>
      <h2 className={styles.title}>Meld je aan voor onze nieuwsbrief</h2>
      <NewsletterForm />
    </MaxWidth>
  </div>
);

export const NewsletterForm = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: "onChange" });

  const { formSettings } = useAppContext();
  const termsLink = formSettings.data.terms_link;
  const successMessage = formSettings.data.newsletter_thanks_message;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    document.body.style.cursor = isSubmitting ? "wait" : "";
    return () => (document.body.style.cursor = "");
  }, [isSubmitting]);

  const onSubmit = async (data) => {
    setError(null);

    const { error } = await fetch("/api/newsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((r) => r.json());

    if (error) {
      setError(error);
    } else {
      setSuccess(true);
    }
  };

  if (success) {
    return <RichText color={WHITE} content={successMessage} />;
  }

  return (
    <div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <input
          className={styles.input}
          name="email"
          placeholder="Jouw e-mailadres"
          {...register("email", { required: true })}
          type="email"
        />
        <Button color={GREEN} disabled={isSubmitting}>
          <span className={styles.buttonLabel}>Aanmelden</span>
          <span className={styles.buttonIcon}>
            <IconDownArrow />
          </span>
        </Button>
      </form>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.subtext}>
        <RichText size={SMALL} color={WHITE}>
          Door je aan te melden voor de nieuwsbrief ga je akkoord met de{" "}
          <Link field={termsLink}>voorwaarden</Link>.
        </RichText>
      </div>
    </div>
  );
};
